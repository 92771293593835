<!-- @format -->

<template>
  <div class="bg-white rounded-lg py-3">
    <div class="p-6 animate-pulse" v-if="loader && !isEnabled">
      <div class="flex items-center justify-between">
        <div>
          <div class="flex gap-2">
            <div
              class="w-32 h-4 bg-gray-200 rounded-full dark:bg-gray-400"
            ></div>
            <div
              class="w-32 h-4 bg-gray-200 rounded-full dark:bg-gray-400"
            ></div>
          </div>
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mt-2.5"
          ></div>
          <div class="flex gap-2">
            <div v-for="loader in tabsData?.length" :key="loader">
              <div
                class="w-16 h-3 bg-gray-200 rounded-full dark:bg-gray-400 mt-10"
              ></div>
            </div>
          </div>
        </div>
        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-52"></div>
      </div>
      <div class="h-0.5 mt-3 bg-gray-200 rounded-full dark:bg-gray-400"></div>
    </div>
    <div v-else>
      <div class="flex p-6 justify-between items-center">
        <div>
          <div class="flex gap-2">
            <div class="text-xl font-medium">Complaint</div>
            <div class="px-2 py-0.5 rounded-md" :class="status?.style">
              {{ status?.title }}
            </div>
          </div>
          <div class="text-gray-500 font-light">
            {{ complaint?.reference_number }}
          </div>
        </div>

        <div v-if="complaint.status === 1 && tabIndex <= 1">
          <div class="font-medium text-xl">
            <span class="text-gray-500">Remaining: </span>
            <span class="text-red"
              >{{
                complaint?.complaint_acknowledgement?.remaining_days
              }}
              days</span
            >
          </div>
        </div>
        <div v-else-if="complaint.status === 1 && tabIndex === 2">
          <div class="font-medium text-xl">
            <span class="text-gray-500">Due Date: </span>
            <span class="text-red">{{
              moment(
                complaint?.complaint_acknowledgement?.extension_date
                  ? complaint?.complaint_acknowledgement?.extension_date
                  : complaint?.complaint_acknowledgement?.due_date
              ).format("MMM DD, YYYY")
            }}</span>
          </div>
        </div>
      </div>
      <div class="drop-shadow-2xl w-full px-6 pb-2">
        <Tabs
          :key="tabsData"
          :tabsData="tabsData"
          :activeTabIndex="tabIndex"
          @index="switchTab"
        />
      </div>
    </div>
    <CreateViewComplaintForm
      v-if="tabIndex === 0"
      :isEnabled="isEnabled"
      :complaint="complaint"
      :isManager="isManager"
      :loader="loader"
    />

    <ComplaintOutcomeForm
      v-if="tabIndex > 1 && complaint?.status >= 1"
      :isEnabled="isEnabled"
      :outcome="complaint?.complaint_outcome"
      :key="complaint?.complaint_outcome"
      :complaintStatus="complaint.status"
      :isManager="true"
    />

    <div v-if="tabIndex === 1 && complaint?.status >= 1">
      <div class="pl-6 font-medium text-lg">Recent Updates</div>
      <div v-if="complaint?.complaint_update.length == 0">
        <div
          class="flex flex-col items-center justify-center text-center w-full py-10"
        >
          <div class="rounded-full p-12 icon-bg">
            <img src="../../../../assets/icons/noData.svg" class="w-32" />
          </div>
          <div class="mt-4 text-teal font-medium flex align-middle">
            <i class="material-icons text-teal w-8">info_outlined</i>
            No Updates Available
          </div>
        </div>
      </div>
      <Timeline
        v-else
        :updatesTimeline="complaint?.complaint_update"
        :loader="loader"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import CreateViewComplaintForm from "../../../../components/newUi/Complaints/CreateViewComplaintForm.vue";
import ComplaintOutcomeForm from "@/components/newUi/Complaints/ComlpaintOutcomeForm.vue";
import Timeline from "@/components/newUi/baseComponents/Timeline.vue";

const route = useRoute();
const isManager = ref(route.params.isManager);
const isEnabled = ref(parseInt(route.params.isEnabled));
const complaintId = ref(route.params.complaintId);
const loader = ref(true);

const store = useStore();
const tabIndex = ref(0);
const status = computed(() => {
  switch (complaint.value?.status) {
    case 0:
      return { title: "Open", style: "text-green  bg-green bg-opacity-10 " };
    case 1:
      return {
        title: "In Progress",
        style: "text-blue  bg-blue bg-opacity-10",
      };

    case 2:
      return { title: "Upheld", style: "text-white bg-gray-800 " };
    case 3:
      return {
        title: "Partially Upheld",
        style: "text-black bg-gray-800 bg-opacity-10",
      };
    case 4:
      return {
        title: "Not Upheld",
        style: "text-white bg-gray-800 bg-opacity-10",
      };
    default:
      return "Unknown";
  }
});

const switchTab = (index) => {
  tabIndex.value = index;
};

const tabsData = computed(() => {
  switch (status.value.title) {
    case "Open":
      return [
        {
          name: "Details",
          value: 0,
        },
      ];
    case "In Progress":
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Updates",
          value: 1,
        },
      ];
    case "Upheld":
    case "Not Upheld":
    case "Partially Upheld":
      return [
        {
          name: "Details",
          value: 0,
        },
        {
          name: "Updates",
          value: 1,
        },
        {
          name: "Outcomes",
          value: 1,
        },
      ];
    default:
      return [
        {
          name: "Details",
          value: 0,
        },
      ];
  }
});

const complaint = computed(() => {
  return store.getters["maComplaints/getComplaint"];
});

onMounted(async () => {
  await store.dispatch("maComplaints/fetchSingleComplaint", {
    complaintId: complaintId.value,
    practice: localStorage.getItem("practice"),
  });
  loader.value = false;
});
</script>
